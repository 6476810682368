<template>
 <div class="panel">
    <div class="main">
      <div v-if="selectedProject == null" class="d-flex justify-content-center mt-5">
        <b-spinner variant='primary' style='width: 3rem; height: 3rem;'></b-spinner>
      </div>
      <section v-else class='w-100 m-auto pt-5'>
        <h4 class='d-inline px-5'>Share Settings</h4>
          <div class='px-5 text-secondary'>
            In addition to the users and groups below, this project is viewable by all administrators of your orgranization.
          </div>
        <div class='bg-white p-5'>
          <h6>Groups</h6>
            <div class='text-secondary'>
              Please select the groups that should have access to this project. New custom groups can be created by your organization administrators.
            </div>
            <share-table
              :tableType="'group'"
              :tableFields="getTableFields('shareGroup')"
              :tableData="allGroups"></share-table>
            <div v-if="!shareGroupsLoaded || !this.companyIsLoaded || !projectSharesLoaded" class="d-flex justify-content-center mt-5">
              <b-spinner small variant='primary'></b-spinner>
            </div>
            <div class='text-secondary center' v-if="addedGroups.length == 0 && shareGroupsLoaded && projectSharesLoaded && this.company">
              This project has no share groups
            </div>
          <h6>Users</h6>
              <div class='text-secondary'>
                Share the project with users in any organization by entering their email address. The project will be visible to the user when they accept the project share.
              </div>
              <share-table
              :tableType="'user'"
              :tableFields="getTableFields('user')"
              :tableData="allUsers"></share-table>
              <div class='text-secondary center' v-if="addedUsers.length == 0">
                This project is not currently shared with any users
              </div>
          <h6 v-if="!companyIsRWDI && projectCompany">RWDI</h6>
          <div style='padding: 0 0.938rem;' class='mt-0' v-if="!companyIsRWDI && projectCompany">
            <b-form-checkbox
              class='mt-0 share-page-checkbox'
              @change='toggleShareWithRWDI'
              :disabled='sharingWithRWDI'
              :checked='sharedWithRWDI'>
              <b-spinner small v-if='sharingWithRWDI'></b-spinner>
              Share project with RWDI
            </b-form-checkbox>
          </div>
        </div>
        </section>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import ShareTable from './ShareTable';
import { EventBus } from '@/network/eventbus';
import {
  GET_COMPANY_SHARE_GROUPS
} from '@/store/actions/permissions';

export default {
  name: 'ProjectSettingsShare',
  components: {
    ShareTable
  },
  async created() {
    this.shareGroupsLoaded = false;
    await this.$store.dispatch(GET_COMPANY_SHARE_GROUPS);
    this.shareGroupsLoaded = true;
  },
  props: {
    projectSharesLoaded: {
      required: true
    }
  },
  methods: {
    getTableFields(table) {
      const usedTables = {
        'user': [
          {key: 'share_status',
            thClass: 'text-left',
            tdClass: 'text-left text-lowercase'},
          {key: 'name',
            thClass: 'text-left',
            tdClass: 'text-left'},
          {key: 'email',
            label: 'Email/Username',
            thClass: 'text-left',
            tdClass: 'text-left'},
          {key: 're_share',
            label: 'Can Share',
            thClass: 'text-center',
            tdClass: 'text-center'},
          {key: 'share_content',
            thClass: 'text-center',
            tdClass: 'text-center'},
          {key: 'delete',
            thClass: 'text-center',
            tdClass: 'text-center'}
        ],
        'shareGroup': [
          {key: 'group_name',
            thClass: 'text-left',
            tdClass: 'text-left'},
          {key: 'default_for_new_projects',
            thClass: 'text-center',
            tdClass: 'text-center'},
          {key: 'delete',
            thClass: 'text-center',
            tdClass: 'text-center'}
        ],
      };
      return usedTables[table];
    },
    async toggleShareWithRWDI(shareWithRWDI) {
      this.sharingWithRWDI = true;
      if(shareWithRWDI) {
        // create project share
        const payload = {
          projectId: this.projectId,
          values: {
            share_with_rwdi: true,
            re_share: false,
            share_status: 'ACCEPTED'
          }
        };
        await this.$store.dispatch('project/createProjectShare', payload);
      } else {
        // delete project share
        // find the project share to the rwdi share group
        let projectShare = this.projectShares.find(share => share.share_group?.company_id !== this.projectCompany.id);

        const payload = {
          projectId: this.projectId,
          projectShareId: projectShare.id
        };
        await this.$store.dispatch('project/deleteProjectShare', payload);
      }
      this.sharingWithRWDI = false;

      if (this.createProjectShareError || this.deleteProjectShareError) {
        EventBus.$emit('TOAST', {
          variant: 'danger',
          content: 'Failed to save new project details'
        });
      } else {
        EventBus.$emit('TOAST', {
          variant: 'success',
          content: 'New project details saved'
        });
      }
    }
  },
  data() {
    return {
      sharingWithRWDI: false,
      shareGroupsLoaded: false
    };
  },
  computed: {
    companyIsLoaded() {
      let is_loaded = !_.isEmpty(this.company);
      return is_loaded;
    },
    companyIsRWDI() {
      return this.selectedProject?.company?.name === 'RWDI';
    },
    projectCompany() {
      return this.selectedProject?.company;
    },
    projectId() {
      return Number(this.$route.params.id);
    },
    allGroups() {
      let choices = this.company?.share_groups;
      if (choices) {
        if (choices.length) {
          for (const group of this.addedGroups) {
            choices = choices.filter(choice => group.id !== choice.id);
          }
          const groups = {
            'choices': choices,
            'added': this.addedGroups
          };
          return groups;
        }
      }
      return [];
    },
    allUsers() {
      const users = {
        'added': this.addedUsers
      };
      return users;
    },
    addedGroups() {
      const groups = [];
      for (const projectShare of this.projectShares) {
        const project_share_group = {
          id: projectShare.share_group?.id,
          group_name: projectShare.share_group?.group_name,
          default_for_new_projects: projectShare.share_group?.default_for_new_projects
        };
        // don't list the share group from RWDI
        if (projectShare.share_group && projectShare.share_group?.company_id === this.projectCompany?.id) {
          groups.push(project_share_group);
        }
      }
      return groups;
    },
    addedUsers() {
      const users = [];
      for (const projectShare of this.projectShares) {
        const project_share_user = {
          id: projectShare?.user?.id,
          email: projectShare?.user?.email || projectShare?.user?.username,
          name: `${projectShare?.user?.first_name} ${projectShare?.user?.last_name}`,
          re_share: projectShare.re_share,
          share_status: projectShare.share_status,
          share_content: projectShare.share_content
        };

        const project_share_user_invite = {
          id: projectShare?.user_invite?.id,
          email: projectShare?.user_invite?.email,
          name: '',
          re_share: projectShare.re_share,
          share_status: projectShare.share_status,
          share_content: projectShare.share_content
        };

        if (projectShare.user) {
          users.push(project_share_user);
        } else if (projectShare.user_invite) {
          users.push(project_share_user_invite);
        }
      }
      return users;
    },
    sharedWithRWDI() {
      return !!this.projectShares.find(share => {
        if(share?.share_group === null) {
          return false;
        } else {
          return share?.share_group?.company_id !== this.projectCompany?.id;
        }
      });
    },
    ...mapGetters([
      'company',
      'loggedInUser',
      'allowedPermissions'
    ]),
    ...mapGetters('project', [
      'selectedProject',
      'projectShares',
      'createProjectShareError',
      'deleteProjectShareError'
    ])
  }
};
</script>

<style scoped>
.panel {
  margin: 0;
  background-color: #fff;
  min-height: 20em;
  display: flex;
}

.main {
  padding: 1rem;
  width: 100%;
}

.loading-spinner {
  all: unset;
}

.side-panel {
  background-color: var(--grey-200);
  margin: 0;
  padding: 1rem;
  width: 20rem;
}

.loading-spinner-container {
  margin-left: 50%;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
}

.center {
  text-align: center;
  margin-top: 0;
}
</style>

<style>
.share-page-checkbox .custom-control-input {
  position: absolute;
}
</style>